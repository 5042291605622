import 'antd/dist/antd.css';
import './NavBar.css';
import { Layout, Menu, Input, Card } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

import food1 from '../img/crab-leg.jpg';
import food2 from '../img/meal1.JPG';
import food3 from '../img/meal2.JPG';
import food4 from '../img/sashimi.jpg';
import food5 from '../img/sushi.jpg';

const { Meta } = Card;

const { Header, Content, Footer, Sider } = Layout;

const { Search } = Input;

const onSearch = value => console.log(value);

const NavBar = () => {
    return(
        <Layout>
            <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
                console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
            >
            <h2 className="logo">Tomita Foods</h2>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                <Menu.Item key="1" icon={<UserOutlined />}>
                nav 1
                </Menu.Item>
                <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                nav 2
                </Menu.Item>
                <Menu.Item key="3" icon={<UploadOutlined />}>
                nav 3
                </Menu.Item>
                <Menu.Item key="4" icon={<UserOutlined />}>
                nav 4
                </Menu.Item>
            </Menu>
            </Sider>
            <Layout style={{ minHeight: '100vh' }}>
            <Header className="site-layout-sub-header-background" style={{ padding: "16px 180px 16px 16px" }}>
                <Search placeholder="input search text" onSearch={onSearch} enterButton />
            </Header>
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <a href="https://youtu.be/SERNtFGulmw">
                    <Card hoverable 
                        className="card-item"
                        cover={<img alt="example" src={food1} />}>
                        <Meta title="Click" />
                    </Card>
                    </a>
                    <a href="https://youtu.be/VDO2vm6U-u0">
                    <Card hoverable 
                        className="card-item"
                        cover={<img alt="example" src={food2} />}>
                        <Meta title="Click" />
                    </Card>
                    </a>
                    <a href="https://youtu.be/SERNtFGulmw">
                    <Card hoverable 
                        className="card-item" 
                        cover={<img alt="example" src={food3} />}>
                        <Meta title="Click" />
                    </Card>
                    </a>
                    <a href="https://youtu.be/VDO2vm6U-u0">
                    <Card hoverable 
                        className="card-item"
                        cover={<img alt="example" src={food4} />}>
                        <Meta title="Click" />
                    </Card>
                    </a>
                    <a href="https://youtu.be/SERNtFGulmw">
                    <Card hoverable 
                        className="card-item"
                        cover={<img alt="example" src={food5} />}>
                        <Meta title="Click" />
                    </Card>
                    </a>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    );
}

export default NavBar;